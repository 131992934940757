const MORE_MENU_TRACKING = {
  eventName: 'access_topbar_nav',
  eventLabel: '',
  eventType: 'click_inbound',
  eventAction: 'More',
  eventPosition: '',
};

const FOR_PHOTOGRAPHERS_MENU_TRACKING = {
  eventName: 'access_topbar_nav',
  eventLabel: '',
  eventType: 'click_inbound',
  eventAction: 'For Photographers',
  eventPosition: '',
};

export const LOGIN_TRACKING = {
  eventName: 'access_topbar_nav',
  eventLabel: 'Login',
  eventType: 'click_inbound',
  eventAction: 'login',
  eventPosition: '',
};

export const SIGNUP_TRACKING = {
  eventName: 'access_topbar_nav',
  eventLabel: 'Signup',
  eventType: 'click_inbound',
  eventAction: 'signup',
  eventPosition: '',
};

const SIGNUP_CREATOR_TRACKING = {
  eventName: 'access_topbar_nav',
  eventLabel: 'Signup_creator',
  eventType: 'click_inbound',
  eventAction: 'signup',
  eventPosition: '',
};

const SIGNUP_BUYER_TRACKING = {
  eventName: 'access_topbar_nav',
  eventLabel: 'Signup_buyer',
  eventType: 'click_inbound',
  eventAction: 'signup',
  eventPosition: '',
};

// SUBMENU TRACKING
const SUB_MENU_STORY_TRACKING = {
  eventName: 'access_topbar_nav',
  eventType: 'click_inbound',
  eventAction: 'More',
  eventLabel: 'Our Story',
  eventPosition: '',
};
const SUB_MENU_TOS_TRACKING = {
  eventName: 'access_topbar_nav',
  eventType: 'click_inbound',
  eventAction: 'More',
  eventLabel: 'Terms & Privacy',
  eventPosition: '',
};
const SUB_MENU_FAQ_TRACKING = {
  eventName: 'access_topbar_nav',
  eventType: 'click_inbound',
  eventAction: 'More',
  eventLabel: 'FAQ & Help',
  eventPosition: '',
};
const SUB_MENU_JOBS_TRACKING = {
  eventName: 'access_topbar_nav',
  eventType: 'click_inbound',
  eventAction: 'More',
  eventLabel: 'Work With Us',
  eventPosition: '',
};
const SUB_MENU_CONTACT_TRACKING = {
  eventName: 'access_topbar_nav',
  eventType: 'click_inbound',
  eventAction: 'More',
  eventLabel: 'Contact Us',
  eventPosition: '',
};
const SUB_MENU_CREATOR_SELL_TRACKING = {
  eventName: 'access_topbar_nav',
  eventType: 'click_inbound',
  eventAction: 'For Photographers',
  eventLabel: 'Sell Your Photos',
  eventPosition: '',
};
const SUB_MENU_IMAGE_LICENSING_TRACKING = {
  eventName: 'access_topbar_nav',
  eventType: 'click_inbound',
  eventAction: 'For Business',
  eventLabel: 'Image Licensing',
  eventPosition: '',
};
const SUB_MENU_PRODUCTIONS_TRACKING = {
  eventName: 'access_topbar_nav',
  eventType: 'click_inbound',
  eventAction: 'For Business',
  eventLabel: 'Photo & Video Production',
  eventPosition: '',
};
const SUB_MENU_PRICING_TRACKING = {
  eventName: 'access_topbar_nav',
  eventType: 'click_inbound',
  eventAction: 'For Business',
  eventLabel: 'Pricing & Licenses',
  eventPosition: '',
};
const SUB_MENU_CREATOR_JOIN_TRACKING = {
  eventName: 'access_topbar_nav',
  eventType: 'click_inbound',
  eventAction: 'For Photographers',
  eventLabel: 'Join the Community',
  eventPosition: '',
};
const SUB_MENU_CREATOR_COLLECTIVE_TRACKING = {
  eventName: 'access_topbar_nav',
  eventType: 'click_inbound',
  eventAction: 'For Photographers',
  eventLabel: 'Get Hired for Shoots',
  eventPosition: '',
};
const SUB_MENU_CREATOR_AWARDS_TRACKING = {
  eventName: 'access_topbar_nav',
  eventType: 'click_inbound',
  eventAction: 'For Photographers',
  eventLabel: 'EyeEm Awards',
  eventPosition: '',
};
const SUB_MENU_CREATOR_MISSIONS_TRACKING = {
  eventName: 'access_topbar_nav',
  eventType: 'click_inbound',
  eventAction: 'For Photographers',
  eventLabel: 'Missions',
  eventPosition: '',
};
const SUB_MENU_SUPPORT_TRACKING = {
  eventName: 'access_topbar_nav',
  eventType: 'click_inbound',
  eventAction: 'For Photographers',
  eventLabel: 'More',
  eventPosition: '',
};
const INFO_NAV_BUY_STOCK_PHOTOS = {
  eventName: 'access_topbar_nav',
  eventType: 'click_inbound',
  eventAction: 'Buy Stock Photos',
  eventLabel: 'Buy Stock Photos',
  eventPosition: '',
};
const INFO_NAV_MAGAZINE_TRACKING = {
  eventName: 'access_topbar_nav',
  eventType: 'click_inbound',
  eventAction: 'More',
  eventLabel: 'Magazine',
  eventPosition: '',
};
const INFO_NAV_PRICING = {
  eventName: 'access_topbar_nav',
  eventType: 'click_inbound',
  eventAction: 'Pricing',
  eventLabel: 'Pricing',
  eventPosition: '',
};
const INFO_NAV_BACK_TO_APP = {
  eventName: 'access_eyeem',
  eventType: 'click_inbound',
  eventAction: 'click_button',
  eventLabel: 'back_to_app',
  eventPosition: 'navbar_info_loggedIn',
};

export const NAVBAR_TRACKING = {
  MORE_MENU_TRACKING,
  FOR_PHOTOGRAPHERS_MENU_TRACKING,
  LOGIN_TRACKING,
  SIGNUP_TRACKING,
  SIGNUP_BUYER_TRACKING,
  SIGNUP_CREATOR_TRACKING,
  SUB_MENU_STORY_TRACKING,
  SUB_MENU_TOS_TRACKING,
  SUB_MENU_FAQ_TRACKING,
  SUB_MENU_JOBS_TRACKING,
  SUB_MENU_CONTACT_TRACKING,
  SUB_MENU_IMAGE_LICENSING_TRACKING,
  SUB_MENU_PRODUCTIONS_TRACKING,
  SUB_MENU_PRICING_TRACKING,
  SUB_MENU_CREATOR_JOIN_TRACKING,
  SUB_MENU_CREATOR_SELL_TRACKING,
  SUB_MENU_CREATOR_COLLECTIVE_TRACKING,
  SUB_MENU_CREATOR_AWARDS_TRACKING,
  SUB_MENU_CREATOR_MISSIONS_TRACKING,
  SUB_MENU_SUPPORT_TRACKING,
  INFO_NAV_MAGAZINE_TRACKING,
  INFO_NAV_BUY_STOCK_PHOTOS,
  INFO_NAV_PRICING,
  INFO_NAV_BACK_TO_APP,
};
