/* eslint-disable no-unused-vars */

import styled from '@emotion/styled';
import { media } from '@eyeem-ui/theme';
import T from '../general/translate.jsx';

export const StyledSearchBarWrapper = styled(({ showSearchInput, ...rest }) => (
  <div {...rest} />
))`
  display: none;
  transition: opacity 0.5s ease-in;

  @media ${media.largeUp} {
    position: relative;
    opacity: ${(props) => (props.showSearchInput ? '1' : '0')};
    display: block;
    margin-left: 28px;
    padding-top: 8px;
    padding-bottom: 8px;
    max-width: 360px;
    width: 100%;
  }
`;

export const StyledSearchBarWrapperWide = styled(
  ({ showSearchInput, ...rest }) => <div {...rest} />
)`
  display: none;
  transition: opacity 0.5s ease-in;

  @media ${media.mediumUp} {
    position: relative;
    opacity: ${(props) => (props.showSearchInput ? '1' : '0')};
    display: block;
    max-width: 480px;
    width: 100%;
  }
`;

export const StyledLeftNavListNoPadding = styled.div`
  display: flex;

  @media ${media.largeUp} {
    width: 50%;
    align-items: center;
  }
`;

export const StyledLeftNavList = styled.div`
  display: flex;

  @media ${media.largeUp} {
    width: 50%;
  }
`;

export const StyledRightNavList = styled.ul`
  display: flex;
  align-items: stretch;
  height: ${(props) => props.theme.navHeight};
  padding-left: 8px;
  position: ${(props) => (props.isMobileSearchOpen ? 'absolute' : 'relative')};
  justify-content: ${(props) => (props.isMobileSearchOpen ? null : 'flex-end')};
  width: ${(props) => (props.isMobileSearchOpen ? '100%' : null)};

  li:nth-of-type(n + 2) {
    opacity: ${(props) => (props.isMobileSearchOpen ? 0 : 1)};
    display: ${(props) => (props.isMobileSearchOpen ? 'none' : null)};
  }

  @media ${media.mediumUp} {
    left: auto;
    right: auto;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }

  @media ${media.largeUp} {
    padding-left: 12px;
  }
`;

export const StyledNavListItem = styled.li`
  position: relative;
  display: ${(props) => {
    if (props.showOnTablet) {
      return 'flex';
    }
    if (props.isDesktopOnly || props.isDesktopXLOnly) {
      return 'none';
    }
    return 'flex';
  }};
  margin: 0 8px;
  white-space: nowrap;
  align-items: center;

  @media ${media.largeUp} {
    display: ${(props) =>
      props.isDesktopOnly || props.isTablet ? 'flex' : null};
    margin-left: 12px;
    margin-right: 12px;
  }
  @media ${media.xlargeUp} {
    display: ${(props) =>
      props.isDesktopXLOnly || props.isTablet ? 'flex' : null};
  }
`;

export const StyledNavItemUserImage = styled.span`
  display: flex;
  cursor: pointer;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
`;

export const StyledNavItemIconCount = styled.span`
  font-size: 12px; // TODO: please compute instead
  line-height: 20px; // TODO: please compute instead
  opacity: ${(props) => (props.itemCount > 0 ? '1' : 0)};
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  background: ${({ theme }) => theme.colors.aqua50};
  color: ${({ theme }) => theme.colors.grey100};
`;

export const StyledNavButtonWrapper = styled(({ hideOn, ...rest }) => (
  <div {...rest} />
))`
  text-align: center;
  ${(props) => (props.hideOn === 'small' ? 'display: none' : null)};

  padding: 12px 24px 8px;

  button {
    font-size: 14px;
  }

  @media ${media.largeUp} {
    padding: 0px 12px;
    display: ${(props) =>
      props.hideOn === 'largeUp' && props.hideOn !== 'small'
        ? 'none;'
        : 'block'};
  }
`;

export const StyledNavButtonContents = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
