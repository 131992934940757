/* @flow */
/* Navbar Logo component */
import t from 'counterpart';
import styled from 'styled-components';

import T from '../../general/translate.jsx';
import Logo from '../../general/eyeemlogo.jsx';
import Link from '../../general/link/';

import { getHomeUrl } from '../../../helpers/tools';

const StyledNavLogo = styled(T)`
  display: flex;
  margin-top: 2px;
`;

function NavbarLogo(props: {
  eventPosition: string,
  isBlack?: boolean,
  isEnterpriseCustomer?: boolean,
  authUser: AuthUser,
}) {
  return (
    <StyledNavLogo
      noTranslate
      eventName={t('navbar.logo.eventName')}
      eventType={t('tracking.eventType.inbound')}
      eventPosition={props.eventPosition}
      eventAction={t('tracking.eventAction.icon')}
      eventLabel={t('navbar.logo.eventLabel')}
      component={!props.authUser ? 'a' : Link}
      href={getHomeUrl({
        authUser: props.authUser,
      })}>
      <Logo
        isBlack={props.isBlack}
        shouldUseProLogo={props.isEnterpriseCustomer}
        square={props.square}
      />
    </StyledNavLogo>
  );
}

NavbarLogo.defaultProps = {
  isBlack: false,
  isEnterpriseCustomer: false,
};

export default NavbarLogo;
